<template>
  <div class="about-wraper">
    <div class="main">
      <h1 class="display-3 font-weight-medium mb-2">Hi, it`s SDA!</h1>
      <p class="subheading font-weight-regular mb-2">
        SDA is an all in one platform for completing courses on different
        topics. Get into something or delve deeper easily by choosing the
        difficulty level. Our application makes it simple to find courses which
        will be most useful to you.
      </p>
      <p class="subheading font-weight-regular mb-4">
        Choose courses from a great selection. Study that one thing you always
        wanted. Get everything you’ll need in just one subscription.
      </p>
      <v-btn
        class="mr-2 white--text"
        router-link
        to="/courses"
        color="teal accent-4"
      >
        <span>Go to courses!</span>
      </v-btn>
    </div>
    <div class="photo">
      <img src="@/assets/about.png" alt="about" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',

  data: () => ({
    //
  }),
}
</script>

<style lang="scss" scoped>
.about-wraper {
  width: 90%;
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main {
  width: 40%;
}
.photo {
  width: 60%;
  img {
    width: 100%;
    height: auto;
  }
}
</style>
